import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_p = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - P'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>P</h2>
     <LatQuotesIntro />
     <p><b>Pacta privata juri publico non derogare possunt</b> - Private contracts cannot derogate from public law.</p>
<p><b>Par delictum</b> - Equal fault.</p>
<p><b>Pari passu</b> - On an equal footing.</p>
<p><b>Partus sequitur ventrem</b> - The offspring follows the mother.</p>
<p><b>Pater est quem nuptiae demonstrant</b> - The father is he whom the marriage points out.</p>
<p><b>Peccata contra naturam sunt gravissima</b> - Wrongs against nature are the most serious.</p>
<p><b>Pendente lite nihil innovetur</b> - During litigation nothing should be changed.</p>
<p><b>Per curiam</b> - In the opinion of the court.</p>
<p><b>Per minas</b> - By means of menaces or threats.</p>
<p><b>Per quod</b> - By reason of which.</p>
<p><b>Post mortem</b> - After death.</p>
<p><b>Prima facie</b> - On the face of it.</p>
<p><b>Prima impressionis</b> - On first impression.</p>
<p><b>Pro hac vice</b> - For this occasion.</p>
<p><b>Pro rata</b> - In proportion.</p>
<p><b>Pro tanto</b> - So far, to that extent.</p>
<p><b>Pro tempore</b> - For the time being.</p>
<p><b>Publici juris</b> - Of public right.</p>

   </Layout>
  )
}

export default LatQuotes_p
